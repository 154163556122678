<template>
  <div class="overlay" :class="{ open: overlayMenuOpen }">
    <div v-if="metadata.tooltip" class="menu-items">
      <fa :icon="['fal', 'info-circle']" />&nbsp;
      <span v-html="metadata.tooltip"></span>
    </div>
    <div v-if="metadata.insight" class="menu-items">
      <fa :icon="['fal', 'bolt']" />&nbsp;
      <span v-html="metadata.insight"></span>
    </div>
    <div v-if="metadata.exports" class="menu-items">
      <QsExportVisualization :qlik-id="qlikId" v-bind="metadata" />
    </div>
    <el-button type="text" @click="fullscreenModalOpen = true">
      <fa :icon="['far', 'expand-alt']" fixed-width />
      Voir en plein écran
    </el-button>
    <el-dialog :visible.sync="fullscreenModalOpen" fullscreen append-to-body>
      <QsObject
        :qlik-id="qlikId"
        v-if="fullscreenModalOpen"
        preserve-on-destroy
        style="height: 85vh"
      />
    </el-dialog>
  </div>
</template>

<script>
import QsObject from "@/components/Qlik/QsObject.vue";
import QsExportVisualization from "@/components/Qlik/QsExportVisualization.vue";
import { getQlikMetadata } from "@/config/qlikMetadata.js";

export default {
  name: "OverlayMenu",
  components: { QsExportVisualization, QsObject },
  props: { qlikId: { type: String, default: "" } },
  data: () => ({ fullscreenModalOpen: false }),
  inject: {
    context: { default: () => ({ overlayMenuOpen: false }) }
  },
  computed: {
    metadata() {
      return getQlikMetadata(this.qlikId);
    },
    overlayMenuOpen() {
      return this.context.overlayMenuOpen;
    }
  }
};
</script>

<style scoped>
.overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: #2d3748;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 12px;
  font-size: 1.5rem;
  overflow: auto;
}
.open {
  display: block;
}
.menu-items {
  padding: 7px 0;
}
</style>
