<template>
  <div class="qs-single-object">
    <OverlayMenu :qlik-id="qlikId" />
    <QsObject v-bind="$props" style="flex-grow: 1" />
    <QsKpi
      v-if="metadata.aggregKpi.qlikId"
      style="height: 35px"
      v-bind="{ ...metadata.aggregKpi, preserveOnDestroy }"
      :title-as-tooltip="true"
    />
  </div>
</template>

<script>
import OverlayMenu from "@/components/OverlayMenu.vue";
import QsObject from "@/components/Qlik/QsObject";
import QsKpi from "@/components/Qlik/QsKpi";
import { getQlikMetadata } from "@/config/qlikMetadata.js";

export default {
  components: { OverlayMenu, QsObject, QsKpi },
  props: ["qlikId", "preserveOnDestroy"],
  computed: {
    metadata() {
      return getQlikMetadata(this.qlikId);
    }
  }
};
</script>

<style scoped>
.qs-single-object {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
