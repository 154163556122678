<template>
  <div :id="$props.qlikId + _uid" class="qlik-object">
    <spinner></spinner>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  name: "QsObject",
  components: { Spinner },
  props: {
    qlikId: String,
    interaction: { type: String, default: "" },
    preserveOnDestroy: { type: Boolean, default: false }
  },
  data() {
    return { qlikModel: null };
  },
  computed: mapState(["app"]),
  mounted() {
    this.app
      .getObject(this.$props.qlikId + this._uid, this.$props.qlikId, {
        noInteraction: this.interaction === "false",
        noAnimate: true
      })
      .then(qlikModel => {
        this.qlikModel = qlikModel;
      });
  },
  beforeDestroy() {
    if (this.qlikModel && !this.preserveOnDestroy) {
      this.app
        .destroySessionObject(this.qlikModel.layout.qInfo.qId)
        .then(() => this.qlikModel.close())
        .then(() => (this.qlikModel = null));
    }
  }
};
</script>

<style scoped>
.qlik-object {
  background-color: transparent;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
