<template>
  <div>
    <el-button
      v-for="(_options, type) in exports"
      :key="type"
      size="mini"
      class="export-btn"
      @click="exportVisualization(type)"
      v-loading="loading[type]"
    >
      <div style="padding: 5px 0">
        <fa
          :icon="['fal', exportsMetadata[type].icon]"
          size="2x"
          :class="exportsMetadata[type].color"
        />
      </div>
      {{ exportsMetadata[type].label }}
    </el-button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  props: {
    qlikId: { type: String, default: "" },
    exports: { type: Object, default: null },
    title: { type: String, default: "" }
  },
  data: () => ({
    qlikVisualization: null,
    loading: { csv: false, xlsx: false, jpg: false, pdf: false }
  }),
  mounted() {
    if (!this.qlikId) return;
    this.app.visualization
      .get(this.qlikId)
      .then(qlikVisualization => (this.qlikVisualization = qlikVisualization));
  },
  computed: {
    ...mapState(["app"]),
    exportsMetadata: () => ({
      csv: {
        label: "CSV",
        icon: "file-csv",
        color: "text-green-500",
        method: "exportData"
      },
      xlsx: {
        label: "XLSX",
        icon: "file-excel",
        color: "text-green-700",
        method: "exportData"
      },
      jpg: {
        label: "JPG",
        icon: "file-image",
        color: "text-blue-600",
        method: "exportImg"
      },
      pdf: {
        label: "PDF",
        icon: "file-pdf",
        color: "text-red-600",
        method: "exportPdf"
      }
    })
  },
  methods: {
    exportVisualization(exportType) {
      this.$mixpanel.track(`Export "${this.title}" chart as ${exportType}`);
      this.loading[exportType] = true;
      const { method } = this.exportsMetadata[exportType];

      // Can't handle error (Qlik API never reject the promise even in case of error)
      this.qlikVisualization[method](this.exports[exportType])
        .then(window.open)
        .finally(() => (this.loading[exportType] = false));
    }
  },
  beforeDestroy() {
    if (!this.qlikVisualization) return;
    this.app
      .destroySessionObject(this.qlikId)
      .then(() => this.qlikVisualization.close())
      .then(() => (this.qlikVisualization = null));
  }
};
</script>

<style scoped>
.export-btn {
  margin-left: 5px;
}
</style>
